import { defineStore } from 'pinia';
import { useCarsStore } from '~/stores/CarsStore';

export const useLocationsStore = defineStore('locationsStore', {
  state: () => ({
    list: null,
    item: null,
    pickUpList: null,
    dropOffList: null,
    loading: false,
    active: null,
  }),
  actions: {
    async getList() {
      const { data: list } = await useFetch('/api/v2/locations/pick-up?fetchData=homepage',{
        key: 'pick-up-locations',
      });
      if (list) this.list = list;
    },
    async getPickUpList() {
      const { data: pickUpList } = await useFetch('/api/v2/locations/pick-up?fetchData=min',{
        key: 'pick-up-locations-min',
      });
      if (pickUpList) this.pickUpList = pickUpList;
    },
    async getDropOffList(id) {
      const { data: dropOffList } = await useFetch(`/api/v2/locations/drop-off/${id}?fetchData=min`,{
        key: 'drop-off-locations',
      });
      if (dropOffList) this.dropOffList = dropOffList;
    },
    async getItem(slug) {
      const route = useRoute();
      if (route.name == 'region') {
        const { data: city } = await useFetch(`/api/v2/regions/${slug}`);
        if (city) this.item = city;
      } else {
        const { data: city } = await useFetch(`/api/v2/location_data/${slug}`);
        if (city) this.item = city;
      }
    },
    async getItemWithCars(slug) {
      const carsStore = useCarsStore();
      const route = useRoute();
      carsStore.list = null;
      if (route.name == 'region') {
        const { data: city } = await useFetch(`/api/v2/regions/${slug}?add_vehicles=true`);
        if (city) this.item = city;
        carsStore.list = city.value.searchResult.vehicles;
      } else {
        const { data: city } = await useFetch(`/api/v2/location_data/${slug}?add_vehicles=true`);
        if (city) this.item = city;
        carsStore.list = city.value.searchResult.vehicles;
      }
    },
  }
});